define("ember-concurrency-decorators/last-value", ["exports", "@ember-decorators/utils/decorator", "@ember/debug", "@ember/object"], function (_exports, _decorator, _debug, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
  /**
   * This decorator allows you to alias a property to the result of a task. You can also provide a default value to use before the task has completed.
   *
   * ```js
   * import Component from '@ember/component';
   * import { task } from 'ember-concurrency-decorators';
   * import { lastValue } from 'ember-concurrency-decorators';
   *
   * export default class ExampleComponent extends Component {
   *   @task
   *   someTask = function*() {
   *     // ...
   *   };
   *
   *   @lastValue('someTask')
   *   someTaskValue;
   *
   *   @lastValue('someTask')
   *   someTaskValueWithDefault = 'A default value';
   * }
   * ```
   *
   * @function
   * @param {string} taskName the name of the task to read a value from
   */
  // eslint-disable-next-line func-names
  var _default = _exports.default = (0, _decorator.decoratorWithRequiredParams)(function lastValue(target, key, desc, _ref) {
    var _ref2 = _slicedToArray(_ref, 1),
      taskName = _ref2[0];
    (true && !(typeof taskName === 'string') && (0, _debug.assert)("ember-concurrency-decorators: @lastValue expects a task name as the first parameter.", typeof taskName === 'string'));
    var initializer = desc.initializer;
    delete desc.initializer;
    var cp = (0, _object.computed)("".concat(taskName, ".lastSuccessful"), function () {
      var lastInstance = (0, _object.get)(this, "".concat(taskName, ".lastSuccessful"));
      if (lastInstance) {
        return (0, _object.get)(lastInstance, 'value');
      }
      if (initializer) {
        return initializer.call(this);
      }
      return undefined;
    });

    // @ts-ignore
    return cp(target, key, desc);
  });
});