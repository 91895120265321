define("semantic-ui-ember/components/ui-rating", ["exports", "ember", "semantic-ui-ember/mixins/base", "semantic-ui-ember/templates/components/ui-rating"], function (_exports, _ember, _base, _uiRating) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _ember.default.Component.extend(_base.default, {
    layout: _uiRating.default,
    module: 'rating',
    classNames: ['ui', 'rating'],
    ignorableAttrs: ['rating'],
    willInitSemantic: function willInitSemantic(settings) {
      this._super.apply(this, arguments);
      if (settings.initialRating == null && this.get('rating')) {
        settings.initialRating = this.get('rating');
      }
    }
  });
});