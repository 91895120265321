define('ember-cli-g-maps/utils/load-google-maps', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  var RSVP = Ember.RSVP;


  /**
   * Get the content of element referencing the Google Maps src
   * @param {String} query [location of HTML element]
   * @return {String|Boolean}
   */
  var getLazyLoadSrc = function getLazyLoadSrc() {
    var query = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'meta[name="ember-cli-g-maps-url"]';

    var meta = void 0;

    if ((typeof query === 'undefined' ? 'undefined' : _typeof(query)) === 'object') {
      meta = query;
    } else {
      meta = Ember.$(query).get(0);
    }

    if (!meta) {
      return false;
    }

    // Return content property or bust
    var content = meta.getAttribute('content') || '';
    return content.length ? content : false;
  };

  /**
   * Request Google Maps script and promise result
   * @param {String} src
   * @return {Promise}
   */
  var lazyLoadGoogleMap = function lazyLoadGoogleMap(src) {
    if (!src) {
      return RSVP.Promise.reject(); // Google Maps source undefined
    }

    return new RSVP.Promise(function (resolve, reject) {
      Ember.$.getScript(src).done(function emberCliGMapsLazyLoadSuccess() {
        resolve(window.google.maps);
      }).fail(function emberCliGMapsLazyLoadFailure(jqXhr) {
        reject(jqXhr); // resolve error
      });
    });
  };

  exports.default = function () {
    var googleMapPromise = void 0;

    /**
     * Attempts to resolve global `google.maps` -> then attempts lazy load -> otherwise rejects
     * @type {Function} loadGoogleMaps
     * @return {Promise}
     * - @resolve {Object} google.maps
     */
    function loadGoogleMaps() {

      /**
       * Resolve available global google.maps
       */

      if (_typeof(window.google) === 'object' && _typeof(window.google.maps) === 'object') {
        return RSVP.Promise.resolve(window.google.maps); // Google maps is loaded
      }

      /**
       * Resolve existing `googleMapsPromise` or initiate lazy load
       */

      if (typeof googleMapPromise === 'undefined') {
        googleMapPromise = loadGoogleMaps.lazyLoadGoogleMap(loadGoogleMaps.getLazyLoadSrc());
      }

      return googleMapPromise;
    }

    loadGoogleMaps.getLazyLoadSrc = getLazyLoadSrc;
    loadGoogleMaps.lazyLoadGoogleMap = lazyLoadGoogleMap;

    return loadGoogleMaps;
  }();
});