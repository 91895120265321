define('ember-cli-g-maps/mixins/g-maps/core/two-way-disable-double-click-zoom', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var observer = Ember.observer;
  exports.default = Ember.Mixin.create({

    /**
     * [observer for component attribute's `disableDoubleClickZoom` updates]
     * @param  {Boolean} 'isMapLoaded'
     * @param  {[Boolean]}  'disableDoubleClickZoom'
     * @return {[Boolean]} [returns false if map not updated]
     */
    _bindDisableDoubleClickZoomToMap: observer('isMapLoaded', 'disableDoubleClickZoom', function () {
      if (!this.get('isMapLoaded')) {
        return false;
      }

      this.get('map.map').setOptions({
        disableDoubleClickZoom: this.get('disableDoubleClickZoom') ? true : false
      });
    })
  });
});