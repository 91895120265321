define('ember-cli-g-maps/mixins/g-maps/core/two-way-lat-lng', ['exports', 'ember-cli-g-maps/utils/g-maps/math'], function (exports, _math) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var observer = Ember.observer,
      on = Ember.on;
  exports.default = Ember.Mixin.create(Ember.Evented, {

    /**
     * [on map load bind map `center_changed` event to `_bindLatLngToModel`]
     */
    _addCenterChangedEvent: on('ember-cli-g-map-loaded', function () {
      var _this = this;

      var googleMapInstance = this.get('map.map');

      GMaps.on('center_changed', googleMapInstance, function () {
        Ember.run.debounce(_this, _this._bindLatLngToModel, 100);
      });
    }),

    /**
     * [observer for component attribute's `lat` and `lng` updates]
     * @param  {Boolean} 'isMapLoaded'
     * @param  {[Number]}  'lat'
     * @param  {[Number]}  'lng'
     * @return {[Boolean]} [returns false if map not updated]
     */
    _bindLatLngToMap: observer('isMapLoaded', 'lat', 'lng', function () {
      if (!this.get('isMapLoaded')) {
        return false;
      }

      var _getProperties = this.getProperties('map', 'lng', 'lat'),
          map = _getProperties.map,
          lat = _getProperties.lat,
          lng = _getProperties.lng;

      var center = map.getCenter();

      // If map is out of sync with app state
      if (!(0, _math.areCoordsEqual)(center.lat(), lat) || !(0, _math.areCoordsEqual)(center.lng(), lng)) {
        map.setCenter(lat, lng);
      }
    }),

    /**
     * [updates component attributes `lat` and `lng` if out of sync]
     * @return {[Boolean]} [returns false if attributes not updated]
     */
    _bindLatLngToModel: function _bindLatLngToModel() {
      var map = this.get('map');

      var _getProperties2 = this.getProperties('lat', 'lng'),
          lat = _getProperties2.lat,
          lng = _getProperties2.lng;

      var center = map.getCenter();

      // Still in sync
      if ((0, _math.areCoordsEqual)(center.lat(), lat) || (0, _math.areCoordsEqual)(center.lng(), lng)) {
        return false;
      }

      // Out of sync
      this.setProperties({ lat: center.lat(), lng: center.lng() });
    }
  });
});