define("semantic-ui-ember/components/ui-embed", ["exports", "ember", "semantic-ui-ember/mixins/base", "semantic-ui-ember/templates/components/ui-embed"], function (_exports, _ember, _base, _uiEmbed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _ember.default.Component.extend(_base.default, {
    layout: _uiEmbed.default,
    module: 'embed',
    classNames: ['ui', 'embed'],
    attributeBindings: ['data-icon', 'data-id', 'data-placeholder', 'data-source', 'data-url'],
    ignorableAttrs: ['data-icon', 'data-id', 'data-placeholder', 'data-source', 'data-url']
  });
});