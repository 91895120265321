define('ember-cli-g-maps/components/g-autocomplete', ['exports', 'ember-cli-g-maps/utils/load-google-maps'], function (exports, _loadGoogleMaps) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      set = Ember.set;
  exports.default = Ember.TextField.extend({
    tagName: 'input',
    classNames: ['g-autocomplete'],
    options: {},

    /**
     * invoke `setup()` with initial input value
     */
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      // Don't break the boot
      if (typeof HTMLInputElement !== 'undefined') {

        // G-Autocomplete's element must be an HTML input
        Ember.assert('g-autocomplete component must have a tagName of `input`', this.element instanceof HTMLInputElement);
      }

      (0, _loadGoogleMaps.default)().then(function () {
        return _this.setup(_this.element);
      });
    },


    /**
     * @public
     * generate new autocomplete instance
     * add `place_changed` event handler
     * set `autocomplete` and `listener` refs on component
     *
     * @param {String} input
     */
    setup: function setup(input) {
      var _this2 = this;

      var autocomplete = new google.maps.places.Autocomplete(input, get(this, 'options'));

      set(this, 'autocomplete', autocomplete);
      set(this, 'listener', autocomplete.addListener('place_changed', function () {
        var placeResult = autocomplete.getPlace() || {};

        if (!placeResult.geometry) {
          return _this2.sendAction('on-select-error', { input: placeResult.name });
        }

        _this2.sendAction('on-select', {
          lat: placeResult.geometry.location.lat(),
          lng: placeResult.geometry.location.lng(),
          place: placeResult
        });
      }));
    },
    didAutocomplete: function didAutocomplete(place) {
      this.send('onSelect', place);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.teardown();
    },


    /**
     * @public
     * remove listener event
     * remove autocomplete instances event listeners
     * if unregister autocomplete
     */
    teardown: function teardown() {
      var autocomplete = get(this, 'autocomplete');
      var listener = get(this, 'listener');

      google.maps.event.removeListener(listener);
      google.maps.event.clearInstanceListeners(autocomplete);
    },


    actions: {
      onSelect: function onSelect(place) {
        this.sendAction('on-select', place);
      }
    }
  });
});