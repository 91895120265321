define('ember-cli-g-maps/mixins/g-maps/overlays', ['exports', 'ember-cli-g-maps/utils/g-maps/child-collection'], function (exports, _childCollection) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  var isArray = Ember.isArray;
  exports.default = Ember.Mixin.create(_childCollection.default.create({
    model: 'overlays',

    namespace: 'overlay',

    /* Supported:
    props: [ 'clickable', 'draggable', 'editable', 'fillColor', 'fillOpacity', 'geodesic', 'paths', 'strokeColor', 'strokeOpacity', 'strokePosition', 'strokeWeight', 'visible', 'zIndex' ],
     events: [ 'click', 'rightclick', 'dblclick', 'mousedown', 'mouseout', 'mouseover', 'mouseup', 'mousemove' ],
    */

    validate: function validatePolygons() {
      var overlays = this.get('overlays');

      if (!overlays) {
        return;
      } // validation not necessary

      if (!isArray(overlays)) {
        throw new Error('g-maps component expects overlays to be an Ember Array');
      }

      // End validation
      if (!overlays[0]) {
        return;
      }

      // Reminder for well formed polygon paths
      if (_typeof(overlays[0]) !== 'object' || isArray(overlays[0])) {
        throw new Error('g-maps overlay items must be objects');
      }
    }
  }));
});